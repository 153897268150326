#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.jiaban-list{margin-top: 10px;}
.times .am-list-item{padding-left: 0;}
.times .am-list-item .am-list-line{display: flex; justify-content: space-between;}
.times .am-list-item .am-list-line .am-list-extra{text-align: left;}
.times .am-list-item .am-list-line:after{height: 0 !important;}
.time_item .am-list-line .am-list-content{}
.time_item .time_title{display: flex;align-items: center; justify-content: space-between}
.time_item .time_title p{margin: 0;}
.time_item .time_title a{display: block; text-align: center;}
.time_item .time_title img{height: 20px; width: 20px;}
.time_item .time_title .line{
    width: 1px;
    height: 40px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-right: 5px;
    margin-right: 10px;
}
.list_jbfooter{display: flex; align-items: center; justify-content: space-between; color: #555;}
.list_jbfooter a{display: block; padding: 0 8px; margin: 10px 0;}
.list_jbfooter .jbtimes{flex: 1; text-align: left;}
.list_jbfooter .jbtimes div{height: 30px; line-height: 30px;}
.list_jbtimes{border-top: 1px solid #eee;}
.list_jbtimes .title{text-align: left; padding: 10px 0}
.jb_footer_text{color: #555; text-align: left; padding-top: 10px}
.jb_footer_text div{padding: 0 0 10px 0;}
.list_jbtimes .jb_footer_ls{border-bottom: 1px solid #eee; padding-bottom: 10px;}
.list_jbtimes .jb_footer_btns{display: flex;align-items: center; justify-content: space-around}
