body {
  background-color: #ffffff!important;
}
#login .header {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
#login .header > p {
  margin: 5px !important;
}
#login .header .p-1 {
  color:#000000;
  font-weight: bold;
  font-size: 20px;
}

#login .header .p-2 {
  color: #444;
  font-size: 17px;  
}
.los_password{display: block; text-align: center; color: #666; margin-top: 10px;}

.login_ico_title .am-input-label{ width: 40px !important;}
.login_ico_title .am-input-extra{margin-top: -15px;
  overflow: initial;}