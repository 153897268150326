.map_box{position: fixed; top:0; left:0; height: 100%; width: 100%; z-index: 999;}
#mapContainer{height: 100%;}
.map_search{position: absolute; top: 50px; padding: 10px; width: 100%; background-color: #fff;}
.map_search #keyword{
    height: 38px;
    line-height: 38px;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    border-radius: 5px;
    padding-left: 10px;
}


html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

#page {
    width: 100%;
    height: 100%;
    position: fixed;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    overflow: hidden;
}

#container {
    -webkit-flex: 1;
}

#panel {
    height: 43%;
    -webkit-flex: 0 0 auto;
    overflow: visible;
    border-top: 1px solid #ccc;
    position: relative;
    z-index: 999;
    /*-webkit-transition: all 0.2s;*/
}

#showHideBtn {
    display: block;
    position: absolute;
    width: 63px;
    height: 32px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -25px;
}

#showHideBtn:after,
#showHideBtn:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}

#showHideBtn:before {
    width: 60px;
    height: 30px;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    border-radius: 30px 30px 0 0;
    border: 1px solid #ccc;
    border-bottom: 0;
}

#showHideBtn:after {
    content: "";
    top: 7px;
    border: 10px solid rgba(255, 0, 0, 0);
    z-index: 99999;
    border-top-color: #ccc;
    /* -webkit-transition: all 0.2s;*/
}

#poiList {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
    background: #fff;
}

#poiList .amap_lib_placeSearch {
    border: none;
}

#panel.hidden {
    height: 0;
    top: 1px;
}

#panel.hidden #showHideBtn {
    /*  top: -30px;*/
}

#panel.hidden #showHideBtn:after {
    /*  -webkit-transform: rotate(180deg);
   -webkit-transform-origin: 50% 4px;*/
    top: -5px;
    border-bottom-color: #ccc;
    border-top-color: transparent;
}

#panel .amap_lib_placeSearch .pageLink {
    font-size: 120%;
    margin: 0 3px;
}

#searchBox {
    position: fixed;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 999;
    top: 55px;
    height: 30px;
}

#tipinput {
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    line-height: 30px;
    padding: 0 7px;
    box-sizing: border-box;
}

#clearSearchBtn {
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    padding: 10px 5px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    color: #999;
}

#clearSearchBtn .del {
    background: #eee;
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

#page.searching #clearSearchBtn {
    display: none;
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 16px solid #eee;
    border-radius: 50%;
    border-top: 16px solid #0b83ea;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: none;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.searching #loader {
    display: block;
}

.searching #page {
    filter: grayscale(1);
    opacity: 0.5;
}

#panel.empty #showHideBtn {
    opacity: 0.5;
}

#emptyTip {
    display: none;
}

#panel.empty #emptyTip {
    display: block;
    position: relative;
    background: #fff;
    width: 100%;
    text-align: center;
    padding: 30px 0;
    color: #666;
}

.poi-more{
    display: none!important;
}
