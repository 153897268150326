
.content-top {
    display: flex;
    padding: 20px 0;
    background: url('../../images/background.jpg')
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    flex: 1;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#record .am-list-header{
    padding:0
}
.warp_list .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap}
