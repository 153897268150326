html,body,#root{padding: 0;margin: 0; height: 100%;overflow-y: auto;}
.content-top {
    display: flex;
    padding: 20px 0;
    background: url('../../images/background.jpg')
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    flex: 1;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.amap-logo{display: none !important;}
.amap-copyright{opacity: 0;}
.contentBox{height: 100%;}
.address_content{height: calc(100% - 430px);}
.address{background-color:#C7DDF3; height: 75px;}
.address .am-list-item{background-color:#C7DDF3;}
.address .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap; padding-left: 25px;}
.site_title{display: flex; align-items: center;}
.site_title img{margin-right: 5px;}
.index-content{height: 100%; background-color: #f9f9f9;}
.top_list .am-list-thumb{height: 50px; width: 50px;}
.top_list .am-list-thumb img{height: 100%; width: 100%;border-radius: 8px;}
.top_list .am-list-content{display: flex; justify-content: space-between;align-items: center;}
.left_bnt{float: right; background-color: #E8F1FA; padding: 5px 10px; border-radius: 5px; color: #428CD6; font-size: 14px;}
.list_title{padding: 10px;}
.list_title span{font-size: 18px; font-weight: 700;margin: 0 5px;}
.step_content{background-color: #fff; padding: 10px 30px 0 30px;}
.step_content .step_item{display: flex; justify-content: flex-start; align-items: flex-start; height: 100px; border-left: 1px solid #e5e5e5;}
.step_content .step_item .step_left span{height: 20px;width: 20px; line-height: 18px; text-align: center; border-radius: 50%; font-size: 10px; background-color: #1296db; color: #fff;position: absolute;left: 20px;}
.step_content .step_item .step_right{margin-left: 20px;}
.step_content .step_des{color: #888;}
.step_item .step_nr{display: flex; justify-content: flex-start; align-items: center;}
.step_item .step_nr a{margin-left: 10px; border:1px solid #4D7DFC; border-radius: 3px; padding: 2px 6px; color:#4D7DFC; }
.step_item .step_nr .wrong{border:1px solid #FF4141; color: #FF4141;}
.step_content .end{height: auto; border:none;}
.daka_bottom{background-color: #fff; height: 150px; overflow: hidden;}
.homeimg .am-image-picker-list{padding: 10px; margin: 0;}
.daka_bottom .title {
    position: relative;
    top: 75px;
    left: 50%;
    margin-left: -30px;
    color: #fff;
    z-index: 999;
}
.homeimg .am-image-picker-list .am-image-picker-upload-btn{border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; text-align: center; line-height: 100px;}
.homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{height: 110px;}
.homeimg .am-image-picker-list .am-image-picker-item .am-image-picker-item-content {
    border-radius:50%;
}
.homeimg .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
    display:none
}
.daka_bottom .bt_title{border-bottom: 1px solid #eee; display: flex; justify-content: flex-start; align-items: center;}
.daka_bottom .bt_title p{margin-right: 10px; }
.daka_bottom .bt_title span{color:#4D7DFC;font-weight: 700; margin: 0 10px;}
.am-tabs{height: auto;}
#mapContainer{height: 100%;width: 100%;}
/*@media only screen and (max-width: 375px) {*/
/*#mapContainer{height: 240px;}*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
    /*#mapContainer{height: 220px;}*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
    /*#mapContainer{height: 160px;}*/
/*}*/
.homeimg .am-image-picker-upload-btn:before, .homeimg .am-image-picker-upload-btn:after{
    width:0;
    height:0;
}
.list_scrollcontent{overflow-y: scroll;}
.daka_rule{padding-top: 10px; color: #5a5a5a}
.daka_rule span{font-size: 14px; font-weight: 700; margin-left: 5px; padding: 0 5px; color: #f62722}
.daka_rule p{padding: 10px; line-height: 22px; margin: 0; color: #959595;}
.daka_item .am-list-body .am-list-line{padding: 18px 15px 18px 0;}
#chucai .homeimg .am-image-picker-list .am-image-picker-upload-btn input,
#waichu .homeimg .am-image-picker-list .am-image-picker-upload-btn input{display: none}
.daka_item .am-list-body .am-list-item .am-list-line .am-list-content{height: 50px;}
.daka_rule_img{padding: 10px;}
.daka_rule_img img{width: 100%;}
.loadingimg{text-align: center; padding-top: 40px}
.loadingimg img{height: 40px; max-width: 100%;}
.more_daka_box{display: flex; justify-content: center;padding-top: 20px;}
.more_daka_box  .item{flex:1;justify-content: center;align-items: center;position: relative;height: 130px;}
.more_daka_box  .item .homeimg{padding: 0;margin: 0;}
.more_daka_box  .item .homeimg .am-image-picker-list{padding: 0}
/*.more_daka_box  .item .title{position: absolute; display: inline-block; height: 50px; line-height: 50px;*/
    /*top: 45%; left: 50%; transform: translate(-50%,-50%)!important; color: #fff;z-index: 999}*/

.more_daka_box  .item .title{position: absolute; display: inline-block; height: 130px; width: 100%; text-align: center; line-height: 130px; color: #fff;z-index: 999}

.more_daka_box  .item .homeimg .am-image-picker-list .am-image-picker-upload-btn{height: 80px; width: 80px; position: absolute;
top: 50%; left: 50%; transform: translate(-50%,-50%)!important;}
.more_daka_box  .item .icon1 .am-image-picker-list .am-image-picker-upload-btn{background-color: #FFA080;height: 60px !important; width: 60px!important;}
.more_daka_box  .item .icon2 .am-image-picker-list .am-image-picker-upload-btn{background-color: #6DDA90;height: 60px !important; width: 60px!important;}
.more_daka_box  .item .title2{color: #003D14}
.more_daka_box  .item .title1{color: #802000}
.more_daka_box  .item .daka_quyu_x{height: 62px; width: 60px;position: relative;top: 50%; left: 50%; transform: translate(-50%,-50%)}
.more_daka_box  .item .daka_quyu_x span{height: 62px; width: 60px; line-height: 62px; text-align: center; position: absolute; display: inline-block;z-index: 999;}
.more_daka_box  .item .daka_quyu_x .homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{
    height:62px !important; width: 60px!important;}
.more_daka_box  .item .daka_quyu_center{height: 80px; width: 80px;position: relative;top: 50%; left: 50%; transform: translate(-50%,-50%)}
.more_daka_box  .item .daka_quyu_center span{height: 80px; width: 80px; line-height: 80px; text-align: center; position: absolute; display: inline-block;z-index: 999;}
.more_daka_box  .item .daka_quyu_center .homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{
    height:80px !important; width: 80px!important;}