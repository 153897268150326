
.box {
    height: 4.2rem;
    width: 90%;
    margin: 0 auto;
    margin-top: 0.8rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 17px;
    background: rgba(0, 51, 102, 0.69);
    color: #fff;
}
.box_l {
    width: 30%;
    padding-left: 5%;
}
.box_c {
    border: 1px solid #4ab9ff;
    padding: 2% 2%;
    font-size: 16px;
    width: 44%;
    box-shadow: 0 0 10px rgba(0, 197, 255, 0.5);
}
.box_r {
    margin-left: 4%;
    margin-right: 3%;
    font-size: 16px;
}
.am-flexbox-item {
    position:'relative';
    left:50%;
    top:50%;
    transform:translate(-50%, 0);
    height:110px;
    width:110px !important;
}
.daka{
    color: rgb(0, 122, 255);
    font-size: 20px;
    position: absolute;
    left: 50%;
    z-index: 9999;
    top: 50%;
    margin-top:30px;
    transform: translate(-50%, 0);
}
.am-flexbox .am-flexbox-item{
    flex:none !important;
}


