#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url('../../../images/ico2.png')no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: flex; align-items: center;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}