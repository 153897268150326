
.content-top {
    display: flex;
    padding: 20px 0;
    background: url('../../images/background.jpg')
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    flex: 1;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#record .am-list-header{
    padding:0
}
.list_item{display: flex; justify-content: flex-start; align-items: center}
.list_item .list_left{ border-left: 1px solid #EAEAEA;}
.list_item .list_left h3{margin:5px 0;font-size: 18px; font-weight: 700; padding-left: 10px;}
.list_item .list_left p{margin: 5px 0; font-size: 14px; color: #999; padding-left: 10px;}
.list_item .list_right{padding: 0 10px;flex: 1;}
.list_item .imglist{display: flex;align-items: center; justify-content: space-between;}
.list_item .list_right .daka_address{color: #999; font-size: 14px; padding-top: 8px;}
.nodata{height: 500px; background:url('../../images/ico2.png')no-repeat center; background-size: 50%;}
.dk_state{display: flex; justify-content: space-between; align-items: center;}
.dk_red{color: #ff4d4f; font-weight: 800; font-size: 16px;}
.list_item .list_img_left img{height: 60px; width: 60px;}
.img_view{position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: 999;background:rgb(18,18,18,0.9);}
.img_view img{max-width: 100%; position: absolute; top: 50%; left:50%;transform: translate(-50%,-50%);}
.am-list-footer{text-align: center;}