html,body,#root{padding: 0;margin: 0; height: 100%;}
.content-top {
    display: flex;
    padding: 20px 0;
    background: url('../../images/background.jpg')
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    flex: 1;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.amap-logo{display: none !important;}
.amap-copyright{opacity: 0;}
.contentBox{height: 100%;}
.address_content{height: calc(100% - 430px);}
.address_content .address{background-color:#C7DDF3; padding: 0 10px; position:absolute; bottom:0;max-width:100%;height: 75px;}
.address .am-list-item{background-color:#C7DDF3;}
.address .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap;}


.index-content{height: 100%; background-color: #f9f9f9; overflow-y: scroll;}
.top_list .am-list-thumb{height: 50px; width: 50px;}
.top_list .am-list-thumb img{height: 100%; width: 100%;}
.top_list .am-list-content{display: flex; justify-content: space-between;align-items: center;}
.left_bnt{float: right; background-color: #E8F1FA; padding: 5px 10px; border-radius: 5px; color: #428CD6; font-size: 14px;}
.list_title{padding: 10px;}
.list_title span{font-size: 18px; font-weight: 700;margin: 0 5px;}
.step_content{background-color: #fff; padding: 10px 30px 0 30px;}
.step_content .step_item{display: flex; justify-content: flex-start; align-items: flex-start; height: 100px; border-left: 1px solid #e5e5e5;}
.step_content .step_item .step_left span{height: 20px;width: 20px; line-height: 18px; text-align: center; border-radius: 50%; font-size: 10px; background-color: #1296db; color: #fff;position: absolute;left: 20px;}
.step_content .step_item .step_right{margin-left: 20px;}
.step_content .step_des{color: #888;}
.step_item .step_nr{display: flex; justify-content: flex-start; align-items: center;}
.step_item .step_nr a{margin-left: 10px; border:1px solid #4D7DFC; border-radius: 3px; padding: 2px 6px; color:#4D7DFC; }
.step_item .step_nr .wrong{border:1px solid #FF4141; color: #FF4141;}
.step_content .end{height: auto; border:none;}
.daka_bottom{background-color: #fff; height: 150px; overflow: hidden;}
.homeimg .am-image-picker-list{padding: 10px; margin: 0;}
.daka_bottom .title {
    position: relative;
    top: 75px;
    left: 50%;
    margin-left: -30px;
    color: #fff;
    z-index: 999;
}
.homeimg .am-image-picker-list .am-image-picker-upload-btn{border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; text-align: center; line-height: 100px;}
.homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{height: 110px;}
.daka_bottom .bt_title{border-bottom: 1px solid #eee; display: flex; justify-content: flex-start; align-items: center;}
.daka_bottom .bt_title p{margin-right: 10px; }
.daka_bottom .bt_title span{color:#4D7DFC;font-weight: 700; margin: 0 10px;}
.am-tabs{height: auto;}
#mapContainer{height: 100%;width: 100%;}
/*@media only screen and (max-width: 375px) {*/
/*#mapContainer{height: 240px;}*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
    /*#mapContainer{height: 220px;}*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
    /*#mapContainer{height: 160px;}*/
/*}*/
.btntemp_daka{margin: 20px auto; border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; box-shadow: 0 2px 6px #999; text-align: center; line-height: 100px;}
.daka-btn{height: 100px;
    width: 100px;
    background-color: #50a1f8;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    margin: auto;
    margin-top: 15px;
color: #fff;}