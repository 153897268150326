#wipeout-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.normalflexqj{display: block;}
.cardfooterflex{display: flex; align-items: center;justify-content:space-around;}
.cardfooterflex div{flex:1;border-right: 1px solid #ddd;}
.cardfooterflex div:last-child{border-right: none;}
.qj_list_item{display: flex;align-items: center; justify-content: flex-start;}
.qj_list_item .list_time{flex: 1;}
.qj_list_item .list_count{color: #e08151; font-weight: 600; font-size: 15px;}
.qj_list_item .list_border_item span{padding: 3px 15px; background-color: #f3f3f3;color: #555; border-radius: 2px; font-size: 13px; }
.qj_list_item img{height: 16px; width: 16px;}
.qj_list_item p{margin: 0 10px;}
.am-card-header{border: 1px solid #f1f1f1;}
.my_list .am-list-item .am-list-line .am-list-extra{color: #9b9b9b;}
.detail_list .am-list-item .am-list-line .am-list-content,.img_box p{color: #999da6;}
.tab_content .detail_nodata {
    text-align: center;
    padding: 50px 0;
}
.normalflex .am-card-footer-extra .qj_list_btn{justify-content:center !important;}