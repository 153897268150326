.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
    flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: flex;
    padding: 11px 15px;
    flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.am-steps-vertical .am-steps-item-content{
    background-color: #EAF5F8;
    border-radius: 10px;
    padding: 10px;
}
.am-steps-vertical.am-steps-small .am-steps-item-title{width: 100%;}
.am-steps-vertical .am-steps-item-description{padding-bottom: 0;}
.am-steps-vertical .am-steps-item{margin-bottom: 10px;}
.am-steps-vertical .am-steps-item-content .check_user{display: flex; align-items: center; justify-content: space-between}
.am-steps-vertical .am-steps-item-content .check_time{font-size: 14px; color: #999; font-weight: 500;}
.am-steps-vertical .am-steps-item-content .check_status{font-size: 14px; color: #878787; margin-right: 10px;}
.img_box{box-sizing: border-box; padding: 15px; background-color: #fff; text-align: center;}
.img_box p{text-align: left; margin-top: 5px;}
.img_box img{max-width: 100%; max-height: 150px;}