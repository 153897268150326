.map_box{position: fixed; top:0; left:0; height: 100%; width: 100%; z-index: 999;}
#mapContainer{height: 100%;}
.map_search{position: absolute; top: 50px; padding: 10px; width: 100%; background-color: #fff;}
.map_search #keyword{
    height: 38px;
    line-height: 38px;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    border-radius: 5px;
    padding-left: 10px;
}