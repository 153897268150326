.my_top{height: 200px; background: url(../../images/info_top.png) no-repeat center; background-size: 100% 100%;}
.my_top .photo{ text-align: center; padding: 20px 0 10px 0; display: flex; align-items: center;}
.my_top .photo .touxiang{height: 80px; width: 80px; border-radius: 50%; padding: 10px;}
.my_top .photo .right{flex: 1; padding-right: 15px;}
.my_top .photo p{width:180px;color: #A3C0EC;text-align: left; border:1px solid #A3C0EC; border-radius: 15px; padding: 3px 8px; margin: 0; font-size: 12px;}
.my_top .photo h3,.my_top .photo h5{color: #fff; margin: 8px 0; text-align: left;}
.my_top .report{display: flex; align-items: center; justify-content: space-around;}
.my_top .report div{flex: 1;}
.my_top .report p{margin: 5px 0; text-align: center; color: #fff;}
.my_top .report .num{font-size: 18px;}
.my_top .report .desc{font-size: 14px; color: #b3daff;}
.my_top .photo .top1{display: flex; align-items: center; justify-content: space-between;}
.help{height: 30px; width: 30px;}
.help img{width: 100%;}