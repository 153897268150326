#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.jb_footer{height: 40px !important; line-height: 0 !important;}
.normalflex .am-card-footer-content{display: none;}
.normalflex .am-card-footer-extra .opbtn_list{display: flex; align-items: center; justify-content: flex-end;}
.list_datas{display: flex; align-items: center;}
.list_datas span{line-height: 25px;}
.jiaban_list .am-card-header{padding: 6px 10px;}
.jiaban_list .am-card-header .am-card-header-extra span{font-weight: 600;}
.remark_content{border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;}
.remark_content .title{font-size: 12px;
    font-weight: 600;
    padding-bottom: 5px;}
.lit_btn{font-size: 12px;
    height: 25px;
    line-height: 25px;}
.jiaban_footer{height: 40px !important; padding-top: 10px !important;}
.my_list_btn{border-radius: 15px;
    color: #000;
    border: 1px solid #999;
    padding: 0 15px; margin-left: 10px; width: 80px; display: inline-block;}
.jb_list_item{display: flex; align-items: center; justify-content: space-around;}
.jb_list_item .jb_list_left{border-right: 1px solid #ddd; padding-right: 15px !important;}
.jb_list_item .jb_item{text-align: center; display: flex; align-items: center;justify-content: flex-start}
.jb_list_item .jb_item img{height: 18px; width: 18px; margin-right: 10px;}
.jb_list_item .jb_item p{margin: 0;}
.jb_list_item .jb_item_btn{text-align: center; margin-top: 10px;}
.jb_list_item .jb_item_btn .list_count{color: #e08151; font-weight: 600;}