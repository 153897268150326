.flex_content{display: flex; align-items: center; justify-content: space-around; padding: 15px;}

.icolist div{height: 50px; width: 50px}
.icolist img{max-width: 100%;}
.icolist p{margin: 5px 0;text-align: center; color: #000;}
.ico_content2{ margin-top: 15px;}
.new_content{padding-bottom: 15px;}
.ico_content2 .ico_title{padding: 15px 15px 9px 15px; border-bottom: 1px solid #eee;}
.content2{padding-bottom: 0 !important;}
.icolist2{position: relative; flex: 1; margin: 0 10px; background-color: #fff; border-radius: 10px; text-align: center; padding: 10px 0;box-shadow: 1px 1px 5px #f8f8f8}
.icolist2 img{height: 35px; width: 35px;}
.icolist2 p{margin: 5px 0 0 0}
.icolist2 span{height: 20px; width: 20px; text-align: center; line-height: 20px; border-radius: 50%; background-color: #F55B62; color: #fff; position: absolute; right: -5px; top: -5px;}
.more_title{color: #000; text-align: center; margin-top: 20px; font-size: 20px;}
.banquan{color: #5a5a5a; text-align: center; padding: 10px; font-size: 12px; background-color: #f9f9f9; position: absolute; bottom: 0; left: 50%; margin-left: -137px;}
.bottom_height{height:calc(100% - 570px); position: relative;}

@media only screen and (max-width: 320px) {
    .bottom_height{height:85px;}
    .banquan{position: static; margin: 0 auto;}
}
.nocontent{background:none !important;}
.xujiabox{margin: 10px; background-color: #fff;}
.xujiabox .am-list-header{color: #1296db}
.gonggao_modal{width: 80%;}
.gb_box{text-align: left;}
.gb_box strong{color: #ed7d31; font-size: 15px;}