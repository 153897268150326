#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}