body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.flex-bottom {
    border-top:1px solid #eee;
    padding-top:3px;
    position: fixed;
    bottom: 0;
    width:100%;
    display: -webkit-flex;
    display: flex;
    height: 50px;
    background-color: #fff;
    z-index: 9999;
}
.flex-bottom > div {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align:center;
}

.flex-bottom > div img{
    display: block;
    margin:2px auto;
    height: 26px;
    width: 26px;
}
.flex-bottom > div span{
    font-size: 12px;
}
.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
html,body,#root{padding: 0;margin: 0; height: 100%;overflow-y: auto;}
.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    background: url(../../static/media/background.e46b920f.jpg)
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.amap-logo{display: none !important;}
.amap-copyright{opacity: 0;}
.contentBox{height: 100%;}
.address_content{height: calc(100% - 430px);}
.address{background-color:#C7DDF3; height: 75px;}
.address .am-list-item{background-color:#C7DDF3;}
.address .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap; padding-left: 25px;}
.site_title{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;}
.site_title img{margin-right: 5px;}
.index-content{height: 100%; background-color: #f9f9f9;}
.top_list .am-list-thumb{height: 50px; width: 50px;}
.top_list .am-list-thumb img{height: 100%; width: 100%;border-radius: 8px;}
.top_list .am-list-content{display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between;-webkit-align-items: center;align-items: center;}
.left_bnt{float: right; background-color: #E8F1FA; padding: 5px 10px; border-radius: 5px; color: #428CD6; font-size: 14px;}
.list_title{padding: 10px;}
.list_title span{font-size: 18px; font-weight: 700;margin: 0 5px;}
.step_content{background-color: #fff; padding: 10px 30px 0 30px;}
.step_content .step_item{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: flex-start; align-items: flex-start; height: 100px; border-left: 1px solid #e5e5e5;}
.step_content .step_item .step_left span{height: 20px;width: 20px; line-height: 18px; text-align: center; border-radius: 50%; font-size: 10px; background-color: #1296db; color: #fff;position: absolute;left: 20px;}
.step_content .step_item .step_right{margin-left: 20px;}
.step_content .step_des{color: #888;}
.step_item .step_nr{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.step_item .step_nr a{margin-left: 10px; border:1px solid #4D7DFC; border-radius: 3px; padding: 2px 6px; color:#4D7DFC; }
.step_item .step_nr .wrong{border:1px solid #FF4141; color: #FF4141;}
.step_content .end{height: auto; border:none;}
.daka_bottom{background-color: #fff; height: 150px; overflow: hidden;}
.homeimg .am-image-picker-list{padding: 10px; margin: 0;}
.daka_bottom .title {
    position: relative;
    top: 75px;
    left: 50%;
    margin-left: -30px;
    color: #fff;
    z-index: 999;
}
.homeimg .am-image-picker-list .am-image-picker-upload-btn{border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; text-align: center; line-height: 100px;}
.homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{height: 110px;}
.homeimg .am-image-picker-list .am-image-picker-item .am-image-picker-item-content {
    border-radius:50%;
}
.homeimg .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
    display:none
}
.daka_bottom .bt_title{border-bottom: 1px solid #eee; display: -webkit-flex; display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.daka_bottom .bt_title p{margin-right: 10px; }
.daka_bottom .bt_title span{color:#4D7DFC;font-weight: 700; margin: 0 10px;}
.am-tabs{height: auto;}
#mapContainer{height: 100%;width: 100%;}
/*@media only screen and (max-width: 375px) {*/
/*#mapContainer{height: 240px;}*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
    /*#mapContainer{height: 220px;}*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
    /*#mapContainer{height: 160px;}*/
/*}*/
.homeimg .am-image-picker-upload-btn:before, .homeimg .am-image-picker-upload-btn:after{
    width:0;
    height:0;
}
.list_scrollcontent{overflow-y: scroll;}
.daka_rule{padding-top: 10px; color: #5a5a5a}
.daka_rule span{font-size: 14px; font-weight: 700; margin-left: 5px; padding: 0 5px; color: #f62722}
.daka_rule p{padding: 10px; line-height: 22px; margin: 0; color: #959595;}
.daka_item .am-list-body .am-list-line{padding: 18px 15px 18px 0;}
#chucai .homeimg .am-image-picker-list .am-image-picker-upload-btn input,
#waichu .homeimg .am-image-picker-list .am-image-picker-upload-btn input{display: none}
.daka_item .am-list-body .am-list-item .am-list-line .am-list-content{height: 50px;}
.daka_rule_img{padding: 10px;}
.daka_rule_img img{width: 100%;}
.loadingimg{text-align: center; padding-top: 40px}
.loadingimg img{height: 40px; max-width: 100%;}
.more_daka_box{display: -webkit-flex;display: flex; -webkit-justify-content: center; justify-content: center;padding-top: 20px;}
.more_daka_box  .item{-webkit-flex:1 1;flex:1 1;-webkit-justify-content: center;justify-content: center;-webkit-align-items: center;align-items: center;position: relative;height: 130px;}
.more_daka_box  .item .homeimg{padding: 0;margin: 0;}
.more_daka_box  .item .homeimg .am-image-picker-list{padding: 0}
/*.more_daka_box  .item .title{position: absolute; display: inline-block; height: 50px; line-height: 50px;*/
    /*top: 45%; left: 50%; transform: translate(-50%,-50%)!important; color: #fff;z-index: 999}*/

.more_daka_box  .item .title{position: absolute; display: inline-block; height: 130px; width: 100%; text-align: center; line-height: 130px; color: #fff;z-index: 999}

.more_daka_box  .item .homeimg .am-image-picker-list .am-image-picker-upload-btn{height: 80px; width: 80px; position: absolute;
top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%)!important; transform: translate(-50%,-50%)!important;}
.more_daka_box  .item .icon1 .am-image-picker-list .am-image-picker-upload-btn{background-color: #FFA080;height: 60px !important; width: 60px!important;}
.more_daka_box  .item .icon2 .am-image-picker-list .am-image-picker-upload-btn{background-color: #6DDA90;height: 60px !important; width: 60px!important;}
.more_daka_box  .item .title2{color: #003D14}
.more_daka_box  .item .title1{color: #802000}
.more_daka_box  .item .daka_quyu_x{height: 62px; width: 60px;position: relative;top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%)}
.more_daka_box  .item .daka_quyu_x span{height: 62px; width: 60px; line-height: 62px; text-align: center; position: absolute; display: inline-block;z-index: 999;}
.more_daka_box  .item .daka_quyu_x .homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{
    height:62px !important; width: 60px!important;}
.more_daka_box  .item .daka_quyu_center{height: 80px; width: 80px;position: relative;top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%)}
.more_daka_box  .item .daka_quyu_center span{height: 80px; width: 80px; line-height: 80px; text-align: center; position: absolute; display: inline-block;z-index: 999;}
.more_daka_box  .item .daka_quyu_center .homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{
    height:80px !important; width: 80px!important;}

.box {
    height: 4.2rem;
    width: 90%;
    margin: 0 auto;
    margin-top: 0.8rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    font-size: 17px;
    background: rgba(0, 51, 102, 0.69);
    color: #fff;
}
.box_l {
    width: 30%;
    padding-left: 5%;
}
.box_c {
    border: 1px solid #4ab9ff;
    padding: 2% 2%;
    font-size: 16px;
    width: 44%;
    box-shadow: 0 0 10px rgba(0, 197, 255, 0.5);
}
.box_r {
    margin-left: 4%;
    margin-right: 3%;
    font-size: 16px;
}
.am-flexbox-item {
    position:'relative';
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%, 0);
            transform:translate(-50%, 0);
    height:110px;
    width:110px !important;
}
.daka{
    color: rgb(0, 122, 255);
    font-size: 20px;
    position: absolute;
    left: 50%;
    z-index: 9999;
    top: 50%;
    margin-top:30px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}
.am-flexbox .am-flexbox-item{
    -webkit-flex:none !important;
            flex:none !important;
}




.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    background: url(../../static/media/background.e46b920f.jpg)
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
#record .am-list-header{
    padding:0
}
.list_item{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center}
.list_item .list_left{ border-left: 1px solid #EAEAEA;}
.list_item .list_left h3{margin:5px 0;font-size: 18px; font-weight: 700; padding-left: 10px;}
.list_item .list_left p{margin: 5px 0; font-size: 14px; color: #999; padding-left: 10px;}
.list_item .list_right{padding: 0 10px;-webkit-flex: 1 1;flex: 1 1;}
.list_item .imglist{display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center; -webkit-justify-content: space-between; justify-content: space-between;}
.list_item .list_right .daka_address{color: #999; font-size: 14px; padding-top: 8px;}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.dk_state{display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: center; align-items: center;}
.dk_red{color: #ff4d4f; font-weight: 800; font-size: 16px;}
.list_item .list_img_left img{height: 60px; width: 60px;}
.img_view{position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: 999;background:rgb(18,18,18,0.9);}
.img_view img{max-width: 100%; position: absolute; top: 50%; left:50%;-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
.am-list-footer{text-align: center;}
#wipeout-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.normalflexqj{display: block;}
.cardfooterflex{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;-webkit-justify-content:space-around;justify-content:space-around;}
.cardfooterflex div{-webkit-flex:1 1;flex:1 1;border-right: 1px solid #ddd;}
.cardfooterflex div:last-child{border-right: none;}
.qj_list_item{display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center; -webkit-justify-content: flex-start; justify-content: flex-start;}
.qj_list_item .list_time{-webkit-flex: 1 1;flex: 1 1;}
.qj_list_item .list_count{color: #e08151; font-weight: 600; font-size: 15px;}
.qj_list_item .list_border_item span{padding: 3px 15px; background-color: #f3f3f3;color: #555; border-radius: 2px; font-size: 13px; }
.qj_list_item img{height: 16px; width: 16px;}
.qj_list_item p{margin: 0 10px;}
.am-card-header{border: 1px solid #f1f1f1;}
.my_list .am-list-item .am-list-line .am-list-extra{color: #9b9b9b;}
.detail_list .am-list-item .am-list-line .am-list-content,.img_box p{color: #999da6;}
.tab_content .detail_nodata {
    text-align: center;
    padding: 50px 0;
}
.normalflex .am-card-footer-extra .qj_list_btn{-webkit-justify-content:center !important;justify-content:center !important;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.jb_footer{height: 40px !important; line-height: 0 !important;}
.normalflex .am-card-footer-content{display: none;}
.normalflex .am-card-footer-extra .opbtn_list{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: flex-end; justify-content: flex-end;}
.list_datas{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;}
.list_datas span{line-height: 25px;}
.jiaban_list .am-card-header{padding: 6px 10px;}
.jiaban_list .am-card-header .am-card-header-extra span{font-weight: 600;}
.remark_content{border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;}
.remark_content .title{font-size: 12px;
    font-weight: 600;
    padding-bottom: 5px;}
.lit_btn{font-size: 12px;
    height: 25px;
    line-height: 25px;}
.jiaban_footer{height: 40px !important; padding-top: 10px !important;}
.my_list_btn{border-radius: 15px;
    color: #000;
    border: 1px solid #999;
    padding: 0 15px; margin-left: 10px; width: 80px; display: inline-block;}
.jb_list_item{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-around; justify-content: space-around;}
.jb_list_item .jb_list_left{border-right: 1px solid #ddd; padding-right: 15px !important;}
.jb_list_item .jb_item{text-align: center; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;-webkit-justify-content: flex-start;justify-content: flex-start}
.jb_list_item .jb_item img{height: 18px; width: 18px; margin-right: 10px;}
.jb_list_item .jb_item p{margin: 0;}
.jb_list_item .jb_item_btn{text-align: center; margin-top: 10px;}
.jb_list_item .jb_item_btn .list_count{color: #e08151; font-weight: 600;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.jiaban-list{margin-top: 10px;}
.times .am-list-item{padding-left: 0;}
.times .am-list-item .am-list-line{display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between;}
.times .am-list-item .am-list-line .am-list-extra{text-align: left;}
.times .am-list-item .am-list-line:after{height: 0 !important;}
.time_item .am-list-line .am-list-content{}
.time_item .time_title{display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center; -webkit-justify-content: space-between; justify-content: space-between}
.time_item .time_title p{margin: 0;}
.time_item .time_title a{display: block; text-align: center;}
.time_item .time_title img{height: 20px; width: 20px;}
.time_item .time_title .line{
    width: 1px;
    height: 40px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-right: 5px;
    margin-right: 10px;
}
.list_jbfooter{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between; color: #555;}
.list_jbfooter a{display: block; padding: 0 8px; margin: 10px 0;}
.list_jbfooter .jbtimes{-webkit-flex: 1 1;flex: 1 1; text-align: left;}
.list_jbfooter .jbtimes div{height: 30px; line-height: 30px;}
.list_jbtimes{border-top: 1px solid #eee;}
.list_jbtimes .title{text-align: left; padding: 10px 0}
.jb_footer_text{color: #555; text-align: left; padding-top: 10px}
.jb_footer_text div{padding: 0 0 10px 0;}
.list_jbtimes .jb_footer_ls{border-bottom: 1px solid #eee; padding-bottom: 10px;}
.list_jbtimes .jb_footer_btns{display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center; -webkit-justify-content: space-around; justify-content: space-around}

.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:72%;
            flex-basis:72%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.warp_text{white-space:nowrap}
.flex_title img{height:18px; width: 18px; margin-right: 10px;}
.flex_title .list_time{font-size: 16px; margin: 0;}
.large_width{width: 100%;}
.little_text{font-size: 12px !important;}

#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.map_box{position: fixed; top:0; left:0; height: 100%; width: 100%; z-index: 999;}
#mapContainer{height: 100%;}
.map_search{position: absolute; top: 50px; padding: 10px; width: 100%; background-color: #fff;}
.map_search #keyword{
    height: 38px;
    line-height: 38px;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    border-radius: 5px;
    padding-left: 10px;
}


html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

#page {
    width: 100%;
    height: 100%;
    position: fixed;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    overflow: hidden;
}

#container {
    -webkit-flex: 1;
}

#panel {
    height: 43%;
    -webkit-flex: 0 0 auto;
    overflow: visible;
    border-top: 1px solid #ccc;
    position: relative;
    z-index: 999;
    /*-webkit-transition: all 0.2s;*/
}

#showHideBtn {
    display: block;
    position: absolute;
    width: 63px;
    height: 32px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -25px;
}

#showHideBtn:after,
#showHideBtn:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}

#showHideBtn:before {
    width: 60px;
    height: 30px;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    border-radius: 30px 30px 0 0;
    border: 1px solid #ccc;
    border-bottom: 0;
}

#showHideBtn:after {
    content: "";
    top: 7px;
    border: 10px solid rgba(255, 0, 0, 0);
    z-index: 99999;
    border-top-color: #ccc;
    /* -webkit-transition: all 0.2s;*/
}

#poiList {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
    background: #fff;
}

#poiList .amap_lib_placeSearch {
    border: none;
}

#panel.hidden {
    height: 0;
    top: 1px;
}

#panel.hidden #showHideBtn {
    /*  top: -30px;*/
}

#panel.hidden #showHideBtn:after {
    /*  -webkit-transform: rotate(180deg);
   -webkit-transform-origin: 50% 4px;*/
    top: -5px;
    border-bottom-color: #ccc;
    border-top-color: transparent;
}

#panel .amap_lib_placeSearch .pageLink {
    font-size: 120%;
    margin: 0 3px;
}

#searchBox {
    position: fixed;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 999;
    top: 55px;
    height: 30px;
}

#tipinput {
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    line-height: 30px;
    padding: 0 7px;
    box-sizing: border-box;
}

#clearSearchBtn {
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    padding: 10px 5px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    color: #999;
}

#clearSearchBtn .del {
    background: #eee;
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

#page.searching #clearSearchBtn {
    display: none;
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 16px solid #eee;
    border-radius: 50%;
    border-top: 16px solid #0b83ea;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: none;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.searching #loader {
    display: block;
}

.searching #page {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    opacity: 0.5;
}

#panel.empty #showHideBtn {
    opacity: 0.5;
}

#emptyTip {
    display: none;
}

#panel.empty #emptyTip {
    display: block;
    position: relative;
    background: #fff;
    width: 100%;
    text-align: center;
    padding: 30px 0;
    color: #666;
}

.poi-more{
    display: none!important;
}

.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff;
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
.flex_remark{display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center;-webkit-justify-content: flex-start;justify-content: flex-start;}
.out_list2{width: 100%;}
.flex_title .remark{padding: 3px 10px; font-size: 12px; border-radius: 3px; background-color: #EBF6FE; color: #108EE9;}

#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}
.list_scrollcontent{overflow-y: scroll;}
.text_left .am-list-line .am-list-extra{text-align: left;}

.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.warp_text{white-space:nowrap}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

.flex_upload{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;-webkit-justify-content:space-between;justify-content:space-between; padding: 0 15px}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 0 0 10px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.warp_text{white-space:nowrap}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{background-color:#108ee9 !important; color:#fff !important;z-index:999;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left,.bottom_btn .bottom_right{border-right: 1px solid #eee;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.title_width .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:60%;flex-basis:60%;}
.upload_title{padding: 15px;}
.upload_img .am-flexbox{padding-bottom: 15px;}
.upload_img .am-flexbox-item{width: 120px !important; height: 100%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}
.list_scrollcontent{overflow-y: scroll;}

#wipeout-list .card-body-list {
    font-size: 14px;
    color:#555;
    line-height: 18px;
    padding: 5px 0;
} 
#wipeout-list .am-card-header-content img {
    width: 36px;
}
#wipeout-list .am-checkbox-input {
    width: 50px;
}
.nodata{height: 500px; background:url(../../static/media/ico2.c116ad6b.png)no-repeat center; background-size: 50%;}
.list_footer{
    border-top: 1px solid #eee;
    height: 30px;
    line-height: 25px;
    color: #108EE9;
    padding-top: 5px;
}
.list_footer .am-card-footer-content{text-align: center;border-right: 1px solid #eee;}
.list_footer .am-card-footer-extra{text-align: center;}
.list_txcontent{display: -webkit-flex;display: flex;}
.list_txcontent .list_tx{height: 70px; width: 60px; margin-right: 10px;}
.list_txcontent .list_tx img{height: 100%; width: 100%;}
#task-list .card-body-list {
    font-size: 12px;
    color:#868686;
    line-height: 18px;
} 
.am-card-header-content img {
    width: 36px;
}
.am-list-item .am-input-label {
    font-size: 15px;
}
.am-list-item .am-input-control input {
    font-size: 15px;
}
.am-textarea-label{
    font-size: 15px;
}
.am-textarea-control textarea {
    font-size: 15px;
}
.am-list-item .am-list-line .am-list-extra {
    color:#222;
}
.xing {
    width: 16px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #f00;
}
#wipeout_editor .am-list-header {
    background-color: #f3f3f3 !important
}
.qj-list{margin-top: 10px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.bottom_btn{color:#108EE9;position: fixed; bottom: 0; display: -webkit-flex; display: flex;-webkit-justify-content:space-around;justify-content:space-around; width: 100%;background-color: #fff;}
.bottom_btn div{-webkit-flex: 1 1;flex: 1 1; text-align: center; height: 40px; line-height: 40px;}
.bottom_btn .bottom_left{border-right: 1px solid #eee;}

html,body,#root{padding: 0;margin: 0; height: 100%;}
.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    background: url(../../static/media/background.e46b920f.jpg)
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.amap-logo{display: none !important;}
.amap-copyright{opacity: 0;}
.contentBox{height: 100%;}
.address_content{height: calc(100% - 430px);}
.address_content .address{background-color:#C7DDF3; padding: 0 10px; position:absolute; bottom:0;max-width:100%;height: 75px;}
.address .am-list-item{background-color:#C7DDF3;}
.address .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap;}


.index-content{height: 100%; background-color: #f9f9f9; overflow-y: scroll;}
.top_list .am-list-thumb{height: 50px; width: 50px;}
.top_list .am-list-thumb img{height: 100%; width: 100%;}
.top_list .am-list-content{display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between;-webkit-align-items: center;align-items: center;}
.left_bnt{float: right; background-color: #E8F1FA; padding: 5px 10px; border-radius: 5px; color: #428CD6; font-size: 14px;}
.list_title{padding: 10px;}
.list_title span{font-size: 18px; font-weight: 700;margin: 0 5px;}
.step_content{background-color: #fff; padding: 10px 30px 0 30px;}
.step_content .step_item{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: flex-start; align-items: flex-start; height: 100px; border-left: 1px solid #e5e5e5;}
.step_content .step_item .step_left span{height: 20px;width: 20px; line-height: 18px; text-align: center; border-radius: 50%; font-size: 10px; background-color: #1296db; color: #fff;position: absolute;left: 20px;}
.step_content .step_item .step_right{margin-left: 20px;}
.step_content .step_des{color: #888;}
.step_item .step_nr{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.step_item .step_nr a{margin-left: 10px; border:1px solid #4D7DFC; border-radius: 3px; padding: 2px 6px; color:#4D7DFC; }
.step_item .step_nr .wrong{border:1px solid #FF4141; color: #FF4141;}
.step_content .end{height: auto; border:none;}
.daka_bottom{background-color: #fff; height: 150px; overflow: hidden;}
.homeimg .am-image-picker-list{padding: 10px; margin: 0;}
.daka_bottom .title {
    position: relative;
    top: 75px;
    left: 50%;
    margin-left: -30px;
    color: #fff;
    z-index: 999;
}
.homeimg .am-image-picker-list .am-image-picker-upload-btn{border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; text-align: center; line-height: 100px;}
.homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{height: 110px;}
.daka_bottom .bt_title{border-bottom: 1px solid #eee; display: -webkit-flex; display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.daka_bottom .bt_title p{margin-right: 10px; }
.daka_bottom .bt_title span{color:#4D7DFC;font-weight: 700; margin: 0 10px;}
.am-tabs{height: auto;}
#mapContainer{height: 100%;width: 100%;}
/*@media only screen and (max-width: 375px) {*/
/*#mapContainer{height: 240px;}*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
    /*#mapContainer{height: 220px;}*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
    /*#mapContainer{height: 160px;}*/
/*}*/
.btntemp_daka{margin: 20px auto; border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; box-shadow: 0 2px 6px #999; text-align: center; line-height: 100px;}
.daka-btn{height: 100px;
    width: 100px;
    background-color: #50a1f8;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    margin: auto;
    margin-top: 15px;
color: #fff;}
body {
  background-color: #ffffff!important;
}
#login .header {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
#login .header > p {
  margin: 5px !important;
}
#login .header .p-1 {
  color:#000000;
  font-weight: bold;
  font-size: 20px;
}

#login .header .p-2 {
  color: #444;
  font-size: 17px;  
}
.los_password{display: block; text-align: center; color: #666; margin-top: 10px;}

.login_ico_title .am-input-label{ width: 40px !important;}
.login_ico_title .am-input-extra{margin-top: -15px;
  overflow: visible;
  overflow: initial;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.detail_item .new_list{margin: 10px; background-color: #fff; border-radius: 6px; box-shadow: 0 2px 4px 1px #E8E8E8;}
.detail_item .new_list .new_list_content{padding: 10px 15px;}
.detail_item .new_list .new_list_title{padding: 10px 15px;
    border-bottom: 1px solid #F1F1F1;
    font-weight: 600; display: -webkit-flex; display: flex;-webkit-align-items: center;align-items: center;-webkit-justify-content: space-between;justify-content: space-between;}
.detail_item .new_list .new_list_item{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;-webkit-justify-content: space-between;justify-content: space-between;}
.detail_item .new_list .new_list_item p{margin: 8px 0; color: #999da6}
.detail_item .new_list .new_list_content .img_box{padding: 0 !important;}


.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.jb_list .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:75%;flex-basis:75%;}

.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
html,body,#root{padding: 0;margin: 0; height: 100%;}
.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    background: url(../../static/media/background.e46b920f.jpg)
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.amap-logo{display: none !important;}
.amap-copyright{opacity: 0;}
.contentBox{height: 100%;}
.address_content{height: calc(100% - 430px);}
.address_content .address{background-color:#C7DDF3; padding: 0 10px; position:absolute; bottom:0;max-width:100%;height: 75px;}
.address .am-list-item{background-color:#C7DDF3;}
.address .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap;}


.index-content{height: 100%; background-color: #f9f9f9; overflow-y: scroll;}
.top_list .am-list-thumb{height: 50px; width: 50px;}
.top_list .am-list-thumb img{height: 100%; width: 100%;}
.top_list .am-list-content{display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between;-webkit-align-items: center;align-items: center;}
.left_bnt{float: right; background-color: #E8F1FA; padding: 5px 10px; border-radius: 5px; color: #428CD6; font-size: 14px;}
.list_title{padding: 10px;}
.list_title span{font-size: 18px; font-weight: 700;margin: 0 5px;}
.step_content{background-color: #fff; padding: 10px 30px 0 30px;}
.step_content .step_item{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: flex-start; align-items: flex-start; height: 100px; border-left: 1px solid #e5e5e5;}
.step_content .step_item .step_left span{height: 20px;width: 20px; line-height: 18px; text-align: center; border-radius: 50%; font-size: 10px; background-color: #1296db; color: #fff;position: absolute;left: 20px;}
.step_content .step_item .step_right{margin-left: 20px;}
.step_content .step_des{color: #888;}
.step_item .step_nr{display: -webkit-flex;display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.step_item .step_nr a{margin-left: 10px; border:1px solid #4D7DFC; border-radius: 3px; padding: 2px 6px; color:#4D7DFC; }
.step_item .step_nr .wrong{border:1px solid #FF4141; color: #FF4141;}
.step_content .end{height: auto; border:none;}
.daka_bottom{background-color: #fff; height: 150px; overflow: hidden;}
.homeimg .am-image-picker-list{padding: 10px; margin: 0;}
.daka_bottom .title {
    position: relative;
    top: 75px;
    left: 50%;
    margin-left: -30px;
    color: #fff;
    z-index: 999;
}
.homeimg .am-image-picker-list .am-image-picker-upload-btn{border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; text-align: center; line-height: 100px;}
.homeimg .am-image-picker-list .am-flexbox .am-flexbox-item{height: 110px;}
.daka_bottom .bt_title{border-bottom: 1px solid #eee; display: -webkit-flex; display: flex; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center;}
.daka_bottom .bt_title p{margin-right: 10px; }
.daka_bottom .bt_title span{color:#4D7DFC;font-weight: 700; margin: 0 10px;}
.am-tabs{height: auto;}
#mapContainer{height: 100%;width: 100%;}
/*@media only screen and (max-width: 375px) {*/
/*#mapContainer{height: 240px;}*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
    /*#mapContainer{height: 220px;}*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
    /*#mapContainer{height: 160px;}*/
/*}*/
.btntemp_daka{margin: 20px auto; border:none;height: 100px; width: 100px; background-color: #50A1F8; color: #fff; border-radius: 50%; box-shadow: 0 2px 6px #999; text-align: center; line-height: 100px;}
.daka-btn{height: 100px;
    width: 100px;
    background-color: #50a1f8;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    margin: auto;
    margin-top: 15px;
color: #fff;}
.flex_content{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-around; justify-content: space-around; padding: 15px;}

.icolist div{height: 50px; width: 50px}
.icolist img{max-width: 100%;}
.icolist p{margin: 5px 0;text-align: center; color: #000;}
.ico_content2{ margin-top: 15px;}
.new_content{padding-bottom: 15px;}
.ico_content2 .ico_title{padding: 15px 15px 9px 15px; border-bottom: 1px solid #eee;}
.content2{padding-bottom: 0 !important;}
.icolist2{position: relative; -webkit-flex: 1 1; flex: 1 1; margin: 0 10px; background-color: #fff; border-radius: 10px; text-align: center; padding: 10px 0;box-shadow: 1px 1px 5px #f8f8f8}
.icolist2 img{height: 35px; width: 35px;}
.icolist2 p{margin: 5px 0 0 0}
.icolist2 span{height: 20px; width: 20px; text-align: center; line-height: 20px; border-radius: 50%; background-color: #F55B62; color: #fff; position: absolute; right: -5px; top: -5px;}
.more_title{color: #000; text-align: center; margin-top: 20px; font-size: 20px;}
.banquan{color: #5a5a5a; text-align: center; padding: 10px; font-size: 12px; background-color: #f9f9f9; position: absolute; bottom: 0; left: 50%; margin-left: -137px;}
.bottom_height{height:calc(100% - 570px); position: relative;}

@media only screen and (max-width: 320px) {
    .bottom_height{height:85px;}
    .banquan{position: static; margin: 0 auto;}
}
.nocontent{background:none !important;}
.xujiabox{margin: 10px; background-color: #fff;}
.xujiabox .am-list-header{color: #1296db}
.gonggao_modal{width: 80%;}
.gb_box{text-align: left;}
.gb_box strong{color: #ed7d31; font-size: 15px;}
.my_top{height: 200px; background: url(../../static/media/info_top.84005903.png) no-repeat center; background-size: 100% 100%;}
.my_top .photo{ text-align: center; padding: 20px 0 10px 0; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
.my_top .photo .touxiang{height: 80px; width: 80px; border-radius: 50%; padding: 10px;}
.my_top .photo .right{-webkit-flex: 1 1;flex: 1 1; padding-right: 15px;}
.my_top .photo p{width:180px;color: #A3C0EC;text-align: left; border:1px solid #A3C0EC; border-radius: 15px; padding: 3px 8px; margin: 0; font-size: 12px;}
.my_top .photo h3,.my_top .photo h5{color: #fff; margin: 8px 0; text-align: left;}
.my_top .report{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-around; justify-content: space-around;}
.my_top .report div{-webkit-flex: 1 1;flex: 1 1;}
.my_top .report p{margin: 5px 0; text-align: center; color: #fff;}
.my_top .report .num{font-size: 18px;}
.my_top .report .desc{font-size: 14px; color: #b3daff;}
.my_top .photo .top1{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between;}
.help{height: 30px; width: 30px;}
.help img{width: 100%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.am-steps-vertical .am-steps-item-content{
    background-color: #EAF5F8;
    border-radius: 10px;
    padding: 10px;
}
.am-steps-vertical.am-steps-small .am-steps-item-title{width: 100%;}
.am-steps-vertical .am-steps-item-description{padding-bottom: 0;}
.am-steps-vertical .am-steps-item{margin-bottom: 10px;}
.am-steps-vertical .am-steps-item-content .check_user{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between}
.am-steps-vertical .am-steps-item-content .check_time{font-size: 14px; color: #999; font-weight: 500;}
.am-steps-vertical .am-steps-item-content .check_status{font-size: 14px; color: #878787; margin-right: 10px;}
.out_list{margin: 10px;background-color: #fff; padding: 10px 0;
    border-radius: 7px;box-shadow: 0 2px 4px 1px #E8E8E8;}
.out_list .am-list-header{padding: 10px 15px 6px 10px; color: #000; font-weight: 600;}
.out_list .am-list-body::after{background-color: #fff !important;}
.flex_title{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between;}
.tj_list .am-list-body{padding-bottom: 10px;}
.tj_list .am-list-body .tj_sj{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-around; justify-content: space-around;}
.tj_list .am-list-body .tj_sj div{-webkit-flex: 1 1;flex: 1 1;}
.tj_list .line{height: 1px; border-top: 1px solid #eee; margin: 0 5px;}
.search_top{width: 100px; border-radius: 5px; margin: 0 auto; padding: 10px 0;}
.search_top .am-button{background-color: #E8F1FA; color: #428CD6; font-size: 16px; height: 35px; line-height: 35px;}
.tj_list .tj_img{border:10px solid #58A6F1;font-size:14px;height: 120px; width: 120px; margin: 10px auto; border-radius: 50%; text-align: center; padding: 15px;}
.tj_img .tj_bz{padding-top: 10px; color: #999;}
.tj_img .tj_num{font-size: 44px; padding: 10px 0;}
.tj_img .tj_link span{text-decoration: underline; color:#0F68BF !important; }
.queka_list .am-list-body .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:30%;flex-basis:30%;}
.help_sc p{margin: 5px 0; line-height: 24px;}
.help_sc p span{color: #EC6239; font-weight: 800;}

.content-top {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    background: url(../../static/media/background.e46b920f.jpg)
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    color:#fff;
    -webkit-flex: 1 1;
            flex: 1 1;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
#record .am-list-header{
    padding:0
}
.warp_list .am-list-item .am-list-line .am-list-brief{white-space:pre-wrap}

.map_box{position: fixed; top:0; left:0; height: 100%; width: 100%; z-index: 999;}
#mapContainer{height: 100%;}
.map_search{position: absolute; top: 50px; padding: 10px; width: 100%; background-color: #fff;}
.map_search #keyword{
    height: 38px;
    line-height: 38px;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    border-radius: 5px;
    padding-left: 10px;
}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.my_top{height: 200px; background: url(../../static/media/info_top.84005903.png) no-repeat center; background-size: 100% 100%;}
.my_top .photo{ text-align: center; padding: 20px 0 10px 0; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
.my_top .photo .touxiang{ max-width: 100%; border-radius: 50%; padding: 10px;}
.my_top .photo .right{-webkit-flex: 1 1;flex: 1 1; padding-right: 15px;}
.my_top .photo p{width:180px;color: #A3C0EC;text-align: left; border:1px solid #A3C0EC; border-radius: 15px; padding: 3px 8px; margin: 0; font-size: 12px;}
.my_top .photo h3,.my_top .photo h5{color: #fff; margin: 8px 0; text-align: left;}
.my_top .report{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-around; justify-content: space-around;}
.my_top .report div{-webkit-flex: 1 1;flex: 1 1;}
.my_top .report p{margin: 5px 0; text-align: center; color: #fff;}
.my_top .report .num{font-size: 18px;}
.my_top .report .desc{font-size: 14px; color: #b3daff;}
.my_top .photo .top1{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between;}
.help{height: 30px; width: 30px;}
.help img{width: 100%;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.am-steps-vertical .am-steps-item-content{
    background-color: #EAF5F8;
    border-radius: 10px;
    padding: 10px;
}
.am-steps-vertical.am-steps-small .am-steps-item-title{width: 100%;}
.am-steps-vertical .am-steps-item-description{padding-bottom: 0;}
.am-steps-vertical .am-steps-item{margin-bottom: 10px;}
.am-steps-vertical .am-steps-item-content .check_user{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between}
.am-steps-vertical .am-steps-item-content .check_time{font-size: 14px; color: #999; font-weight: 500;}
.am-steps-vertical .am-steps-item-content .check_status{font-size: 14px; color: #878787; margin-right: 10px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.check_step{padding: 15px;}
.am-steps-vertical .am-steps-item-content{
    background-color: #EAF5F8;
    border-radius: 10px;
    padding: 10px;
}
.am-steps-vertical.am-steps-small .am-steps-item-title{width: 100%;}
.am-steps-vertical .am-steps-item-description{padding-bottom: 0;}
.am-steps-vertical .am-steps-item{margin-bottom: 10px;}
.am-steps-vertical .am-steps-item-content .check_user{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between}
.am-steps-vertical .am-steps-item-content .check_time{font-size: 14px; color: #999; font-weight: 500;}
.am-steps-vertical .am-steps-item-content .check_status{font-size: 14px; color: #878787; margin-right: 10px;}
.img_box{box-sizing: border-box; padding: 15px; background-color: #fff; text-align: center;}
.img_box p{text-align: left; margin-top: 5px;}
.img_box img{max-width: 100%; max-height: 150px;}
.am-list-item .am-list-line .am-list-extra {
    -webkit-flex-basis:66%;
            flex-basis:66%;
}
.am-list-item .am-list-line-wrap .am-list-extra {
    word-break:break-word;
}
.header-approval {
    display: -webkit-flex;
    display: flex;
    padding: 11px 15px;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color:#fff; 
}
.header-approval-avatar {
    width: 65px;
}
.header-approval-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color:#444;
    padding-left: 17px;
}
.header-approval-state {
    width: 65px;
}
.header-approval-state img{
    width: 65px;
}
#vertical-timeline {
    position: relative;
    padding: 0 20px;
    padding-bottom: 1em;
    background-color: #fff;
}
.vertical-container {
    margin: 0 auto;
}
#vertical-timeline:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 40px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
}
.vertical-timeline-block:first-child {
    margin-top: 0;
}
.vertical-timeline-block {
    position: relative;
    padding-top: 20px;
}
.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    margin-top: 20px;
}
.vertical-timeline-content {
    background: #f5f5f5;
}
.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 1em;
}
.vertical-timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f5f5f5;
}
.am-list-item .am-list-line .am-list-content {
    font-size: 15px !important;
}
.am-list-item .am-list-line .am-list-extra {
    font-size: 14px !important;
}
.qingjia_img_box{background-color: #fff;padding: 10px;}
.qingjia_img_box img{max-width: 100%;
    height: 200px;
    display: block;}
.queka_list .am-list-body .am-list-item .am-list-line .am-list-extra{-webkit-flex-basis:30%;flex-basis:30%;}
