
.flex-bottom {
    border-top:1px solid #eee;
    padding-top:3px;
    position: fixed;
    bottom: 0;
    width:100%;
    display: flex;
    height: 50px;
    background-color: #fff;
    z-index: 9999;
}
.flex-bottom > div {
    flex: 1;
    text-align:center;
}

.flex-bottom > div img{
    display: block;
    margin:2px auto;
    height: 26px;
    width: 26px;
}
.flex-bottom > div span{
    font-size: 12px;
}
.content-top {
    display: flex;
    padding: 20px 0;
}
.header-photo {
    width: 30%;
    text-align: center;
}
.header-name {
    flex: 1;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}